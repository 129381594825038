import type { HTMLAttributes } from "react";

import type { ColumnConfig } from "../../../design-tokens/dimensions";
import { FlexItemRoot } from "./styles";

export type FlexItemProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * {@link ColumnConfig} which defines sizes at different grid breakpoints for this item.
   */
  columnConfig?: ColumnConfig;
};

/**
 * Renders a column to sit inside a FlexRow.
 */
export function FlexItem({ columnConfig, children, ...rest }: FlexItemProps): JSX.Element {
  return (
    <FlexItemRoot columnConfig={columnConfig} data-testid={FlexItem.name} {...rest}>
      {children}
    </FlexItemRoot>
  );
}
