/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { FunctionComponent, useEffect, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Routes } from '@common/routes';
import { I18nFacade } from '@i18n/I18nFacade';

export const LocalizationSwitch: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const {
    i18n: { language: lang }
  } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const newPath = I18nFacade.updatePathWithCorrectLocale(history.location, lang);

    if (newPath) history.push(newPath);
  }, [history, history.location, lang]);

  return (
    <Switch>
      <Route path={Routes.LangRoot.url({ lang })}>{children}</Route>
    </Switch>
  );
};
