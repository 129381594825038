/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { StatusCode } from '@utils/constants';
import { checkErrorStatusCode, extractErrorStatus, extractAxiosErrorResponse } from '@utils/network';
import { AuthError } from './errors';
import { StatusToAuthStatus } from './types';

/**
 * Decorator that works as a middleware for handling 401 response error,
 * Must be used in sub-stores since needs access to the rootStore property.
 */
export function withAuth<T>(target: T, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
  const originalFn = descriptor.value;

  descriptor.value = async function(...args: any[]) {
    if (!this.auth && process.env.NODE_ENV === 'development') {
      console.error('Developer Error: withAuth decorator needs to have Auth store injected');
      return;
    }

    try {
      return await originalFn.call(this, ...args);
    } catch (e) {
      const status = extractErrorStatus(extractAxiosErrorResponse(e));
      const isAuthError = checkErrorStatusCode([StatusCode.Unauthorized, StatusCode.Forbidden], e);

      if (isAuthError) {
        this.auth.setAuthState(StatusToAuthStatus[status]);

        return Promise.reject(new AuthError(`request failed: status=${status}`));
      }

      return Promise.reject(e);
    }
  };

  return descriptor;
}
