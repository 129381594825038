/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { FunctionComponent } from 'react';
import { SwitchProps, Route, RouteComponentProps, Switch } from 'react-router-dom';
import NotFoundPage from '@views/NotFoundPage/NotFoundPage';

type Props = SwitchProps & {
  component?: FunctionComponent<RouteComponentProps>;
};

export const SwitchWithNotFound: FunctionComponent<Props> = ({ component = NotFoundPage, children }) => {
  return (
    <Switch>
      {children}
      <Route component={component} />
    </Switch>
  );
};
