import styled from "@emotion/styled";
import { rem } from "polished";
import type { CSSProperties } from "react";

export const Root = styled.div<{ display: CSSProperties["display"]; maxWidth: number }>`
  display: ${({ display }) => display};
  margin: 0 auto;
  max-width: ${({ maxWidth }) => rem(maxWidth)};
  position: relative;
  width: 100%;
`;
