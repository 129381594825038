/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export enum LoaderState {
  Pending = 'pending',
  Finished = 'finished',
  Failed = 'failed'
}

export interface LoaderItem {
  state: LoaderState;
}
