import styled from "@emotion/styled";
import { transparentize } from "polished";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii, filters } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";
import type { LinkProps, LinkSize } from "./Link";

const BaseLink = styled.a`
  border: none;
  border-radius: ${borderRadii.r4};
  cursor: pointer;
  display: inline-flex;
  font-family: ${typography.families.cta};
  font-weight: ${typography.weights.medium};
  gap: ${sizes.s16.rem};
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: 150ms background;

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    filter: ${filters.disabled};
  }
`;

export const TextLink = styled(BaseLink)`
  --mask-icon-color: ${colors.blueLight};
  --mask-icon-size: ${sizes.s16.rem};
  color: ${colors.blueLight};
  font-size: ${typography.sizes[18]};
  padding: 0;
  gap: ${sizes.s8.rem};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    text-decoration: underline;
    filter: opacity(70%);
  }

  &[disabled] {
    text-decoration: none;
  }
`;

const getSizeStyles = (entitySize: LinkSize = "m") => `
  --mask-icon-size: ${entitySize === "m" ? sizes.s16.rem : sizes.s12.rem};
  font-size: ${entitySize === "m" ? typography.sizes[18] : typography.sizes[16]};
  gap: ${entitySize === "m" ? sizes.s12.rem : sizes.s8.rem};
  padding: ${entitySize === "m" ? sizes.s16.rem : sizes.s12.rem} ${
  entitySize === "m" ? sizes.s24.rem : sizes.s16.rem
};
`;

export const PrimaryLinkButton = styled(BaseLink)<LinkProps>`
  --mask-icon-color: ${colors.blueDeep};
  ${({ entitySize }) => getSizeStyles(entitySize)};
  align-items: center;
  background-color: ${colors.blue};
  color: ${colors.blueDeep};

  &:hover {
    background: ${colors.blueLight};
  }

  &:active {
    background: ${transparentize(0.2, colors.blue)};
  }

  &[disabled] {
    background: ${transparentize(0.2, colors.blue)};
  }
`;

export const SecondaryLinkButton = styled(BaseLink)<LinkProps>`
  --mask-icon-color: ${colors.white};
  ${({ entitySize }) => getSizeStyles(entitySize)};
  align-items: center;
  background-color: ${colors.baseMid};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.betaBase};
  }

  &:active {
    background-color: ${colors.baseDark};
  }

  &[disabled] {
    background-color: ${colors.baseMid};
  }
`;
