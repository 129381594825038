/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { FunctionComponent, useEffect, Suspense, useState, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loading } from '@components/layouts/Loading/Loading';
import { I18n } from '@i18n/i18n';
import { useStore } from '@stores/useStore';
import { noop } from '@utils/helpers';
import { GeneralErrorPage } from '@views/GeneralErrorPage/GeneralErrorPage';

type Props = {
  i18n: I18n;
};

export const LocalizationLoader: FunctionComponent<PropsWithChildren<Props>> = ({ i18n, children }) => {
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const { localisation } = useStore();

  useEffect(() => {
    const loadTranslation = async () => {
      try {
        i18n.detectUserLocale();
        await i18n.getTranslations();

        localisation.setLocalisationInstance(i18n);
      } catch {
        setIsError(true);
      }
    };

    loadTranslation().catch(noop);
    // eslint-disable-next-line
  }, [history, i18n]);

  /**
   * Hide other pages when BFF is unavailable.
   * In such cases user will see the Common Error page.
   */
  return (
    <Suspense fallback={<Loading />}>
      {isError ? (
        <GeneralErrorPage standalone={true} />
      ) : (
        <I18nextProvider i18n={i18n.i18nInstance}>{children}</I18nextProvider>
      )}
    </Suspense>
  );
};
