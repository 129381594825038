/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export enum LoaderName {
  GetEmailStatus = 'user--get-email-status',
  StartVerification = 'user--start-verification',
  ProceedVerification = 'user--proceed-verification'
}

export enum UserVerificationMethod {
  Code = 'code'
}
