import type { InputHTMLAttributes } from "react";

import { Root } from "./styles";

type BaseCheckboxProps = {
  /**
   * Whether the current entry is in an invalid state.
   */
  isInvalid?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * A basic HTML input checkbox.
 *
 * Please note that this should not be used without a label and is here for atomic design purposes only.
 */
export function BaseCheckbox({ isInvalid = false, ...rest }: BaseCheckboxProps): JSX.Element {
  return (
    <Root
      data-testid={BaseCheckbox.name}
      isInvalid={isInvalid}
      aria-invalid={isInvalid}
      type="checkbox"
      {...rest}
    />
  );
}
