/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { History, Location } from 'history';
import { inject, injectable } from 'inversify';
import Cookies from 'js-cookie';
import { action, makeObservable, observable } from 'mobx';
import { NEWCOMER_TARGET_ROUTE_STORAGE_KEY, CookieName } from '@common/constants';
import { Routes } from '@common/routes';
import { QueryParam } from '@common/types';
import { LoaderName } from '@stores/domains/AccountSelect/constants';
import { LoaderState } from '@stores/domains/Loader/types';
import { TYPES } from '@stores/types';
import { parseBool } from '@utils/helpers';
import { setTargetRoute } from '@utils/navigation';
import { Loader } from '../Loader';
import { loadable } from '../Loader/decorators';
import { Requests } from './Requests';
import { SelectAccounts } from './types';

@injectable()
export class AccountSelect {
  @inject(TYPES.Loader) loader: Loader;

  availableActiveRsAccounts: SelectAccounts[] = [];
  availableArchivedRsAccounts: SelectAccounts[] = [];

  constructor() {
    makeObservable(this, {
      availableActiveRsAccounts: observable,
      availableArchivedRsAccounts: observable,
      setAvailableActiveAccounts: action,
      setAvailableArchivedAccounts: action
    });
  }

  setAvailableActiveAccounts(availableActiveRsAccounts: SelectAccounts[]): void {
    this.availableActiveRsAccounts = availableActiveRsAccounts;
  }

  setAvailableArchivedAccounts(availableArchivedRsAccounts: SelectAccounts[]): void {
    this.availableArchivedRsAccounts = availableArchivedRsAccounts;
  }

  @loadable(LoaderName.GetAccountSelection)
  async getAccountSelection(): Promise<void> {
    const { active, archived } = await Requests.getAccountSelection();

    this.setAvailableActiveAccounts(active);
    this.setAvailableArchivedAccounts(archived);
  }

  @loadable(LoaderName.SelectAccount, false)
  async selectAccount(rsAccountId: string): Promise<void> {
    const data = await Requests.selectAccount({ rsAccountId });
    window.location.assign(data.redirectUrl);
  }

  @loadable(LoaderName.CreateAccount, false)
  async createAccount(): Promise<void> {
    const data = await Requests.createAccount();
    window.location.assign(data.redirectUrl);
  }

  @loadable(LoaderName.PerformAutoAction, false)
  async performAutoAction(
    lang: string,
    history: History,
    location: Location,
    skipCookieDeclaration?: QueryParam
  ): Promise<void> {
    if (this.availableActiveRsAccounts.length === 1) {
      const { accountId } = this.availableActiveRsAccounts[0];
      try {
        await this.selectAccount(accountId);
      } catch (error) {
        this.loader.setLoaderState(LoaderName.PerformAutoAction, LoaderState.Finished);

        return Promise.reject(error);
      }
    } else if (!this.availableActiveRsAccounts.length && !this.availableArchivedRsAccounts.length) {
      if (typeof skipCookieDeclaration === 'string' && parseBool(skipCookieDeclaration)) {
        Cookies.set(CookieName.SkipCookieDeclaration, 'true');
      }

      setTargetRoute(location, NEWCOMER_TARGET_ROUTE_STORAGE_KEY);
      history.push(Routes.Newcomers.url({ lang }) + location.search);
    } else {
      this.loader.setLoaderState(LoaderName.PerformAutoAction, LoaderState.Finished);
    }
  }
}
