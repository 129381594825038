/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { ErrorCodeMessages } from '@utils/translations';

export const PAGE_HEADING_ID = 'page-heading';

export const APP_ROOT_ID = 'app';

export const API_PREFIX = '/api';

export const LANG_QUERY_NAME = 'lang';
export const SECURITY_BLOCK_ID = 'runescape-accounts__security-page-content';

export enum StatusCode {
  // 2xx
  Ok = 200,
  NoContent = 204,
  // 3xx,
  Found = 302,
  TemporaryRedirect = 307,
  // 4xx
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  // 5xx
  InternalServerError = 500
}

export const ErrorStatusMessages = {
  [StatusCode.InternalServerError]: ErrorCodeMessages.GeneralError
};

export const ErrorCodes = {
  IncorrectState: 'INCORRECT_STATE',
  TooManyAccounts: 'TOO_MANY_ACCOUNTS',
  FeatureUnavailable: 'FEATURE_UNAVAILABLE',
  ValidationError: 'VALIDATION_ERROR',
  NotFound: 'NOT_FOUND',
  Forbidden: 'FORBIDDEN'
};

/* Securtiy block */
export const HEADER_CONTENT_TYPE = 'content-type';

export enum ContentType {
  Html = 'text/html',
  Json = 'application/json'
}
/* endblock */

export enum ZIndexes {
  SecurtiyIframe = 10000
}

export const HEADER_LOCALE = 'locale';

export const ACCOUNT_HINT_PLACEHOLDER = '<hint>';
export const LANGUAGE_PLACEHOLDER = '<lang>';
export const RS_LANGUAGE_CODES_MAP = {
  'en-GB': '0',
  'de-DE': '1',
  'fr-FR': '2',
  'pt-BR': '3'
};

export const PLAYER_SUPPORT_LANGUAGE_CODES_MAP = {
  'en-GB': 'en-gb',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'pt-BR': 'pt-br'
};

export enum LanguageCodeType {
  RSUrl = 'RSUrl',
  RSQuery = 'RSQuery',
  Native = 'Native',
  PlayerSupport = 'PlayerSupport'
}

export enum LanguagePrefixesMap {
  RSUrl = 'l=',
  RSQuery = 'set_lang='
}

export type LANGUAGE_CODES_MAP = typeof PLAYER_SUPPORT_LANGUAGE_CODES_MAP | typeof RS_LANGUAGE_CODES_MAP;
