import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../utils/line-clamp";
import { colors } from "../../design-tokens/colors";
import { globalTextMaxWidth } from "../../design-tokens/dimensions";
import { typography } from "../../design-tokens/typography";
import type { StyledProps } from "./Heading";

const defaultColor = colors.white;

export const getHeadingStyle = (props: StyledProps) => {
  return css`
    color: ${props.color ?? defaultColor};
    font-family: ${typography.families.heading};
    font-weight: ${typography.weights.medium};
    letter-spacing: ${typography.letterSpacing[3]};
    margin: 0 auto;
    max-width: ${rem(globalTextMaxWidth)};
    text-align: ${props.align};
    width: 100%;
    ${lineClamp(typography.lineHeights[32], props.lines)}
  `;
};

const H1 = styled.h1<StyledProps>`
  ${getHeadingStyle}

  font-size: ${typography.sizes[48]};
  font-weight: ${typography.weights.bold};
  ${({ lines }) => lineClamp(typography.lineHeights[56], lines)}
`;

const H2 = styled.h2<StyledProps>`
  ${getHeadingStyle}

  font-size: ${typography.sizes[32]};
  line-height: ${typography.lineHeights[44]};
`;

const H3 = styled.h3<StyledProps>`
  ${getHeadingStyle}

  font-size: ${typography.sizes[24]};
  line-height: ${typography.lineHeights[32]};
`;

const H4 = styled.h4<StyledProps>`
  ${getHeadingStyle}

  font-size: ${typography.sizes[20]};
  line-height: ${typography.lineHeights[32]};
`;

const H5 = styled.h5<StyledProps>`
  ${getHeadingStyle}

  font-size: ${typography.sizes[18]};
  line-height: ${typography.lineHeights[28]};
`;

const H6 = styled.h6<StyledProps>`
  ${getHeadingStyle}

  font-size: ${typography.sizes[16]};
  line-height: ${typography.lineHeights[20]};
`;

export const headings = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
};
