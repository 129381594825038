/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AccountMigrationData, AccountMigrationState } from './types';

export function checkMigrationAwaitingTokenReadinessOrFailure(data: AccountMigrationData): boolean {
  const { state } = data;

  return [
    AccountMigrationState.AwaitingToken,
    AccountMigrationState.Failed,
    AccountMigrationState.Invalidated
  ].includes(state);
}
