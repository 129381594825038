/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { History } from 'history';
import { GenericObject } from '@common/types';
import { AuthError } from '@stores/domains/Auth/errors';

/**
 * Empty placeholder function, usually used for error omits.
 */
/* eslint-disable-next-line @typescript-eslint/no-empty-function */
export const noop = (): void => {};

/**
 * Helper for parse value to boolean type
 * @param value - value for parse
 * @return {boolean} - match boolean value
 */
export const parseBool = (value: string | null | undefined): boolean => `${value}`.toLocaleLowerCase() == 'true';

export const getQueryParams = (history: History): URLSearchParams => {
  const { search } = history.location;

  return new URLSearchParams(search);
};

/**
 * Check if object is empty
 * @param obj - object to check
 */
export const isEmptyObject = (obj: GenericObject): boolean => Object.keys(obj).length === 0;

export const executeWhenNotAuthError = async <T extends (...args: any) => Promise<any>>(
  e,
  fn: T
): Promise<ReturnType<T> | void> => {
  if (!(e instanceof AuthError)) {
    return await fn();
  }

  return Promise.resolve();
};
