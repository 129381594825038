import styled from "@emotion/styled";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import type { IconProps } from "./Icon";

type IconSpanProps = Omit<IconProps, "icon"> & {
  src: string;
};

export const Root = styled.span<IconSpanProps>`
  --mask-icon-image: url(${({ src }) => src}) no-repeat center/contain;
  --mask-icon-color: ${({ color }) => color};
  --mask-icon-size: ${({ height }) => height};

  align-items: center;
  background: var(--mask-icon-image);
  color: transparent;
  display: inline-flex;
  justify-content: center;
  min-height: var(--mask-icon-size, ${sizes.s24.rem});
  min-width: var(--mask-icon-size, ${sizes.s24.rem});
  overflow: hidden;
  user-select: none;

  @supports (mask: var(--mask-icon-image)) {
    background: var(--mask-icon-color, ${colors.white});
    mask: var(--mask-icon-image);
  }
`;
