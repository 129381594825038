/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { ParsedUrlQuery, parse } from 'querystring';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// A custom hook that builds on useLocation to parse the query strings.
// TODO: add generic like `<R extends Record<string, string | string[]>>(): R`
export const useQuery = (): ParsedUrlQuery => {
  const { search } = useLocation();
  const normalizedSearch = search.replace(/^\?/, '');

  return useMemo(() => parse(normalizedSearch), [normalizedSearch]);
};
