/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { createContext, FunctionComponent, PropsWithChildren } from 'react';
import { Store } from './createStore';

// TODO: Add mobx dev tools

interface Props {
  store: Store;
}

export const StoreContext = createContext<Store | null>(null);

export const StoreProvider: FunctionComponent<PropsWithChildren<Props>> = ({ store, children }) => (
  <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
);
