import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem, transparentize } from "polished";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii, filters } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";
import { Loader } from "../Loader";
import type { ButtonProps, ButtonSize } from "./Button";

const BaseButton = styled.button<{ isLoading: boolean }>`
  align-items: center;
  border: none;
  border-radius: ${borderRadii.r8};
  cursor: ${({ isLoading }) => (!isLoading ? "pointer" : "wait")};
  display: inline-flex;
  font-family: ${typography.families.cta};
  font-weight: ${typography.weights.medium};
  gap: ${sizes.s16.rem};
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: 150ms background;

  &:disabled {
    cursor: ${({ isLoading }) => (!isLoading ? "not-allowed" : "wait")};
    filter: ${({ isLoading }) => (!isLoading ? filters.disabled : "initial")};
  }
`;

const getSizeStyles = (entitySize: ButtonSize) => `
  --mask-icon-size: ${entitySize === "m" ? sizes.s16.rem : sizes.s12.rem};
  font-size: ${entitySize === "m" ? typography.sizes[18] : typography.sizes[16]};
  gap: ${entitySize === "m" ? sizes.s12.rem : sizes.s8.rem};
  height: ${entitySize === "m" ? rem(50) : rem(40)};
  min-width: ${entitySize === "m" ? rem(240) : rem(200)};
  padding: ${entitySize === "m" ? sizes.s16.rem : sizes.s12.rem} ${
  entitySize === "m" ? sizes.s24.rem : sizes.s16.rem
};

`;

export const PrimaryButton = styled(BaseButton)<{ entitySize: ButtonSize }>`
  --mask-icon-color: ${colors.blueDeep};
  ${({ entitySize }) => getSizeStyles(entitySize)};
  background-color: ${colors.blue};
  color: ${colors.blueDeep};

  ${({ isLoading }) =>
    !isLoading &&
    css`
      &:hover {
        background: ${colors.blueLight};
      }

      &:active {
        background: ${transparentize(0.2, colors.blue)};
      }

      &:disabled {
        background: ${transparentize(0.2, colors.blue)};
      }
    `}
`;

export const SecondaryButton = styled(BaseButton)<{ entitySize: ButtonSize }>`
  --mask-icon-color: ${colors.white};
  ${({ entitySize }) => getSizeStyles(entitySize)};
  background-color: ${colors.baseMid};
  color: ${colors.white};

  ${({ isLoading }) =>
    !isLoading &&
    css`
      &:hover {
        background-color: ${colors.betaBase};
      }

      &:active {
        background-color: ${colors.baseDark};
      }

      &:disabled {
        background-color: ${colors.baseMid};
      }
    `}
`;

export const TertiaryButton = styled(BaseButton)<{ entitySize: ButtonSize }>`
  --mask-icon-color: ${colors.white};
  ${({ entitySize }) => getSizeStyles(entitySize)};
  background-color: transparent;
  border: 1px solid ${transparentize(0.7, colors.white)};
  color: ${colors.white};
  justify-content: flex-start;

  &:hover {
    background-color: ${transparentize(0.9, colors.white)};
  }

  &:active {
    background-color: ${transparentize(0.95, colors.white)};
  }

  &:disabled {
    background-color: transparent;
  }
`;

export const LinkButton = styled(BaseButton)<ButtonProps>`
  --mask-icon-color: ${colors.blueLight};
  --mask-icon-size: ${sizes.s16.rem};
  align-items: flex-start;
  background-color: transparent;
  border-radius: ${borderRadii.r4};
  color: ${colors.blueLight};
  font-size: ${typography.sizes[18]};
  gap: ${sizes.s8.rem};
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    filter: opacity(70%);
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
  }
`;

export const ButtonLoader = styled(Loader)<{ entitySize: ButtonProps["entitySize"] }>`
  width: ${({ entitySize }) => (entitySize === "m" ? rem(28) : rem(20))};
`;
