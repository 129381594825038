/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { i18n } from 'i18next';

export enum AuthStatus {
  UNKNOWN,
  AUTHORIZED,
  UNAUTHORIZED,
  FORBIDDEN
}

export const StatusToAuthStatus = {
  '401': AuthStatus.UNAUTHORIZED,
  '403': AuthStatus.FORBIDDEN
};

export interface InitialAuthState {
  authStatus: AuthStatus;
}

export interface LoginInfo {
  loginLink: string;
}

export interface RegistrationInfo {
  registrationLink: string;
}

export type LangData = {
  lang: i18n['language'];
};

export type RegistrationStartRequestData = LangData & { migrationId: string };

export type LogoutResponse = {
  redirectURL: string;
};
