import styled from "@emotion/styled";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { filters } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";

export const Root = styled.div`
  margin: ${sizes.s16.rem} 0;
`;

export const Label = styled.label`
  align-items: center;
  display: inline-flex;
  justify-content: left;
`;

export const LabelText = styled.span<{ isDisabled?: boolean }>`
  color: ${colors.white};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  filter: ${({ isDisabled }) => (isDisabled ? filters.disabled : "none")};
  flex: 1;
  font-size: ${typography.sizes["18"]};
  font-weight: ${typography.weights.normal};
  letter-spacing: ${typography.letterSpacing["2"]};
  line-height: ${typography.lineHeights["28"]};
  padding-left: ${sizes.s8.rem};
  text-align: left;
`;
