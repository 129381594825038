/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export const getGoogleAnalyticsScript = (
  tagId: string,
  cookieDomain: string,
  cookiePrefix: string,
  userId?: string
): string => {
  return `
        <!-- Google Analytics -->
        window['ga-disable-${tagId}'] = false;
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${tagId}', {
          'cookie_prefix': '${cookiePrefix}',
          'cookie_domain': '${cookieDomain}',
          ${userId ? `'user_id': '${userId}'` : ''}
        });
        <!-- End Google Analytics -->
      `;
};

export const defaultGoogleConsent = `
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("consent", "default", {
          ad_storage: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          wait_for_update: 500,
        });
        gtag("set", "ads_data_redaction", true);
        `;
