/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosResponse } from 'axios';
import { NetworkError } from '@common/types';
import { APP_ROOT_ID, ContentType, HEADER_CONTENT_TYPE, SECURITY_BLOCK_ID, ZIndexes, StatusCode } from './constants';
import { isAxiosResponse, extractAxiosErrorResponse, extractErrorStatus } from './network';

/**
 * Script to handle captcha resolve and prevent page refreshing after its submitting
 * On successful submit CAPTHCA triggers `window.top.location.reload(true)` function call
 * which force reloading of the current page .
 * This script allows us to send message from iframe, which we've created, to the main window.
 * `window.onmessage` handler receives this message and remove iframe from dom preventing page reloading.
 * Sending `{ captcha: true }` gives us an additional flag to check that it is from CAPTCHA iframe.
 */
const script = `
<script>
  window.addEventListener(
    'beforeunload',
    function() {
      window.parent.postMessage({ captcha: true }, '*');
    },
    false
  );
</script>
`;

/**
 * Adds custom script to Cloudflare CAPTCHA or block pages.
 * @param {string} html - Cloudflare CAPTCHA or block page
 * @returns adjusted page
 */
export const addBeforeUnloadListener = (html: string): string => html.replace('</body>', `${script}</body>`);

/**
 * Adds a custom iframe that handles Cloudflare's responses (e.g., CAPTCHA, block message).
 * @param {string} initialData - Cloudflare's response (e.g., a page with CAPTCHA)
 * @param {NetworkError | Error} exception - original error
 * @returns response to the original api request
 */
export const addSecurityIframe = (initialData: string, exception: NetworkError | Error): any => {
  const existingStateIframe = document.getElementById(SECURITY_BLOCK_ID);

  if (!existingStateIframe) {
    const stateIframe = document.createElement('iframe');
    stateIframe.id = SECURITY_BLOCK_ID;
    stateIframe.setAttribute('data-testid', SECURITY_BLOCK_ID);
    stateIframe.setAttribute(
      'style',
      // eslint-disable-next-line max-len
      `width: 100%; height: 100%; background: #ffffff; position: fixed; top: 0; left: 0; z-index: ${ZIndexes.SecurtiyIframe}`
    );
    stateIframe.setAttribute('frameborder', '0');

    const rootElement = document.getElementById(APP_ROOT_ID) ?? document.body;
    rootElement.appendChild(stateIframe);

    // Adding script to send message from captcha iframe
    if (stateIframe.contentDocument) {
      stateIframe.contentDocument.open();
      stateIframe.contentDocument.write(addBeforeUnloadListener(initialData));
      stateIframe.contentDocument.close();
    } else {
      return Promise.reject(exception);
    }

    return new Promise((resolve, reject) => {
      window.onmessage = async (message: MessageEvent) => {
        const updatedStateIframe = document.getElementById(SECURITY_BLOCK_ID);

        if (message.data.captcha && updatedStateIframe) {
          window.onmessage = null;

          updatedStateIframe.onload = (): void => {
            const iframeContentDocument = (updatedStateIframe as HTMLIFrameElement).contentDocument;

            try {
              const data = JSON.parse(iframeContentDocument?.body.innerText ?? '{}');

              updatedStateIframe.onload = null;
              updatedStateIframe.parentNode!.removeChild(updatedStateIframe);

              const status = extractErrorStatus(data);

              if (status >= StatusCode.BadRequest) {
                return reject({ data });
              }

              return resolve({ data, headers: {} } as AxiosResponse);
            } catch (e) {
              /*
               * `SyntaxError` is the only one expected error that can be ignored in this try/catch statement.
               * It means that CF has returned its content istead of API response.
               * */
              if (!(e instanceof SyntaxError)) {
                updatedStateIframe.onload = null;
                updatedStateIframe.parentNode!.removeChild(updatedStateIframe);

                return reject(e);
              }
            }
          };
        } else {
          return reject(exception);
        }
      };
    });
  }
};

/**
 * Adds CAPTCHA or block overlay on api requests.
 * If axios reponse has `text/html` content-type and 'cloudflare' server in headers, we consider it as Cloudflare CAPTCHA or block pages.
 * In this case we add iframe overlay which contain original response content.
 * If captcha is solved, we repeat user's original request and return it as a part of `addSecurityCheck`.
 *
 * Otherwise, we pass original response as is.
 *
 * @param {Promise<AxiosResponse<T>>} request - axios request
 * @returns original or repeated axios response
 */
export async function addSecurityCheck<T>(request: Promise<AxiosResponse<T>>): Promise<AxiosResponse> {
  try {
    return await request;
  } catch (exception) {
    const error = extractAxiosErrorResponse(exception);

    if (!isAxiosResponse(error)) {
      return Promise.reject(exception);
    }

    const { data, headers } = error;

    if (headers[HEADER_CONTENT_TYPE]?.includes(ContentType.Html) && headers?.server === 'cloudflare') {
      return addSecurityIframe(data, exception);
    } else {
      return Promise.reject(exception);
    }
  }
}
