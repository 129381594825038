/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export enum LoaderName {
  CreateAccount = 'account-select--create-account',
  GetAccountSelection = 'account-select--get-account-selection',
  SelectAccount = 'account-select--select-account',
  SelectAccountAutomatically = 'account-select--select-account-automatically',
  PerformAutoAction = 'account-select--perform-auto-action'
}
