/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import styled from '@emotion/styled';
import { theme, Flex } from '@jagex/design_jagex';

export const FlexWrapper = styled(Flex)`
  align-items: center;
  flex: 1;

  @media (max-width: ${theme.breakpoints[0]}) {
    flex-direction: column;
  }
`;
