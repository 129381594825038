import styled from "@emotion/styled";
import type { CSSProperties } from "react";

import { lineClamp } from "../../../utils/line-clamp";
import type { ColorValue } from "../../design-tokens/colors";
import { colors } from "../../design-tokens/colors";
import { globalTextMaxWidth, sizes } from "../../design-tokens/dimensions";
import { typography } from "../../design-tokens/typography";

export const Root = styled.small<{
  lines?: number;
  align?: CSSProperties["textAlign"];
  color?: ColorValue;
}>`
  color: ${({ color }) => color ?? colors.white};
  display: block;
  font-size: ${typography.sizes["14"]};
  font-weight: ${typography.weights.normal};
  letter-spacing: ${typography.letterSpacing["2"]};
  margin: ${sizes.s8.rem} auto;
  max-width: ${globalTextMaxWidth};
  text-align: ${({ align }) => align};
  ${({ lines }) => lineClamp(typography.lineHeights[20], lines)};
`;
