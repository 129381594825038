/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Container } from 'inversify';
import { Loader, Snackbars, Localisation, Auth, User, AccountSelect, AccountMigration, GameAccounts } from './domains';
import { TYPES } from './types';
import { GameAccountsPage, AccountMigrationPage, NewcomersPage, BeginYourAdventurePage } from './views';

export const container = new Container({ defaultScope: 'Singleton' });
// Domains
container.bind<Loader>(TYPES.Loader).to(Loader);
container.bind<Localisation>(TYPES.Localisation).to(Localisation);
container.bind<Snackbars>(TYPES.Snackbars).to(Snackbars);
container.bind<Auth>(TYPES.Auth).to(Auth);
container.bind<User>(TYPES.User).to(User);
container.bind<AccountSelect>(TYPES.AccountSelect).to(AccountSelect);
container.bind<AccountMigration>(TYPES.AccountMigration).to(AccountMigration);
container.bind<GameAccounts>(TYPES.GameAccounts).to(GameAccounts);

// Views
container.bind<GameAccountsPage>(TYPES.GameAccountsPage).to(GameAccountsPage);
container.bind<AccountMigrationPage>(TYPES.AccountMigrationPage).to(AccountMigrationPage);
container.bind<NewcomersPage>(TYPES.NewcomersPage).to(NewcomersPage);
container.bind<BeginYourAdventurePage>(TYPES.BeginYourAdventurePage).to(BeginYourAdventurePage);
