/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Flex } from '@jagex/design_jagex';
import React, { FunctionComponent } from 'react';
import { PageLoader } from '@components/animations';
import { CenteredContainer } from '@components/layouts';

export const Loading: FunctionComponent = () => (
  <CenteredContainer>
    <Flex sx={{ justifyContent: 'center' }} mt={2} mb={2}>
      <PageLoader />
    </Flex>
  </CenteredContainer>
);
