/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { LinkedCharactersResponseData, LimitOfLinkedCharactersResponse, LinkedCharactersRank } from './types';

export const Requests = {
  getAccounts: (options: AxiosRequestConfig = {}): Promise<LinkedCharactersResponseData> => {
    return extractResponse<LinkedCharactersResponseData>(api.get('/users/current/accounts', options));
  },
  saveAccountsRank: (data: LinkedCharactersRank, options: AxiosRequestConfig = {}): Promise<void> => {
    return extractResponse<void>(api.put('/users/current/accounts/rank', data, options));
  },
  getLimitOfLinkedCharacters: (options: AxiosRequestConfig = {}): Promise<LimitOfLinkedCharactersResponse> => {
    return extractResponse<LimitOfLinkedCharactersResponse>(api.get('/users/current/accounts/limit', options));
  }
};
