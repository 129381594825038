import styled from "@emotion/styled";
import { rem } from "polished";

import { colors } from "../../design-tokens/colors";
import { globalTextMaxWidth, sizes } from "../../design-tokens/dimensions";

export const Root = styled.hr`
  border: none;
  border-bottom: ${rem(1)} solid ${colors.betaBase};
  margin: ${sizes.s32.rem} auto;
  max-width: ${rem(globalTextMaxWidth)};
  width: 100%;
`;
