/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { CreateAccountRequestData } from './types';

export const Requests = {
  createAccount: (data: CreateAccountRequestData, config?: AxiosRequestConfig): Promise<void> => {
    return extractResponse<void>(api.post('/users/current/accounts/create', data, config));
  }
};
