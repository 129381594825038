/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { BoxFlow } from '@jagex/jds';
import React, { FunctionComponent, useEffect, useRef, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SNACKBAR_DURATION } from '@stores/domains';
import { SnackbarType } from '@stores/domains/Snackbars/types';
import { Z_INDEX } from '@styles/vars';

interface Props {
  duration?: number;
  level?: SnackbarType;
  onClose: () => void;
  afterClose?: () => void;
}

export const Snackbar: FunctionComponent<PropsWithChildren<Props>> = ({
  duration = DEFAULT_SNACKBAR_DURATION,
  level = 'info',
  onClose,
  afterClose,
  children
}) => {
  const { t } = useTranslation();
  const timeout = useRef<number>();

  useEffect(() => {
    if (!timeout.current && duration) {
      // FIXME: setTimeout returns a Timeout object, but has implemented `Symbol.asPrimitive` which results in `number`
      timeout.current = setTimeout(() => {
        onClose();

        if (afterClose) {
          afterClose();
        }
      }, duration) as any;
    }

    return () => clearTimeout(timeout.current);
  }, [onClose, afterClose, duration, timeout]);

  return (
    <BoxFlow.Toast isOpen={true} onDismissClick={onClose} level={level} style={{ zIndex: Z_INDEX.toast }}>
      {typeof children === 'string' ? t(children) : children}
    </BoxFlow.Toast>
  );
};
