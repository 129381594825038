import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { lighten, rem, rgba } from "polished";

import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { borderRadii } from "../../design-tokens/effects";
import { typography } from "../../design-tokens/typography";

const autofillStyles = css`
  background-color: transparent !important;
  box-shadow: none !important;
  color: ${colors.white} !important;
  // Currently the only way to prevent blink styles applied in webkit
  transition: background-color 600000s 0s, color 600000s 0s;
`;

export const Root = styled.input`
  background: transparent;
  color: ${colors.white};
  border-radius: ${borderRadii.r8};
  border: ${rem(1)} solid ${colors.betaBase};
  font-family: ${typography.families.body};
  font-size: ${typography.sizes["18"]};
  font-weight: ${typography.weights.normal};
  height: ${sizes.s56.rem};
  letter-spacing: ${typography.letterSpacing["2"]};
  line-height: ${typography.lineHeights["28"]};
  max-width: ${rem(512)};
  padding: ${sizes.s16.rem} ${sizes.s24.rem};
  width: 100%;

  // Overrides autofill styles setup by UAS for webkit
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected,
  &:-internal-autofill-selected:hover,
  &:-internal-autofill-selected:focus {
    ${autofillStyles}
  }

  // Overrides autofill styles for other vendors (seperated to not break the webkit prefix selector)
  &:autofill,
  &:autofill:hover,
  &:autofill:focus {
    ${autofillStyles}
  }

  &:hover {
    border-color: ${lighten(0.1, colors.betaBase)};
  }

  &:focus {
    border-color: ${colors.blue};

    &:hover {
      border-color: ${colors.blueLight};
    }
  }

  &::placeholder {
    color: ${rgba(colors.white, 0.5)};
  }

  &[aria-invalid="true"] {
    border-color: ${colors.red};

    &:focus,
    &:hover {
      border-color: ${colors.redLight};
    }
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &[disabled] {
    border: none;
    cursor: not-allowed;
  }
`;
