import type React from "react";
import type { HTMLAttributes } from "react";

import { Icon } from "../../atoms/Icon";
import { Paragraph } from "../../atoms/Paragraph";
import { sizes } from "../../design-tokens/dimensions";
import type { flatIcons } from "../../design-tokens/icons";
import { CloseButton, Root, StatusIcon } from "./styles";

const levelIcons: Record<AlertLevel, keyof typeof flatIcons> = {
  error: "info",
  success: "success",
  info: "info",
};

export type AlertSize = "s" | "m";
export type AlertLevel = "error" | "success" | "info";

export type AlertProps = {
  /**
   * The size variant to use.
   */
  entitySize?: AlertSize;
  /**
   * Level variant of the alert.
   */
  level?: AlertLevel;
  /**
   * Callback function for dissmis button click. If not provided the dissmiss button won't be displayed.
   */
  onDismissClick?: (e: React.PointerEvent<HTMLButtonElement>) => void;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Displays feedback to the user at defined aesthetic levels.
 */
export function Alert({
  children,
  entitySize = "m",
  level = "info",
  onDismissClick,
  ...rest
}: AlertProps) {
  return (
    <Root data-testid={Alert.name} role="alert" entitySize={entitySize} level={level} {...rest}>
      <StatusIcon icon={levelIcons[level]} />
      <Paragraph entitySize={entitySize === "m" ? "l" : "m"} align="left" color="currentColor">
        {children}
      </Paragraph>
      {onDismissClick ? (
        <CloseButton
          onClick={onDismissClick}
          variant="inline"
          before={<Icon icon="close" height={sizes.s16.rem} />}
          data-testid={`${Alert.name}-CloseButton`}
        />
      ) : null}
    </Root>
  );
}
