/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { useContext } from 'react';
import { Store } from './createStore';
import { StoreContext } from './Provider';

export const useStore = (): Store => {
  const store = useContext(StoreContext);

  if (!store) {
    throw new Error('Developer Error: useStore must be used within the StoreProvider');
  }

  return store;
};
