/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { History } from 'history';
import { injectable, inject } from 'inversify';
import { ACCOUNT_MIGRATION_ID_KEY } from '@common/constants';
import { Routes } from '@common/routes';
import { GenericObject } from '@common/types';
import { Loader, AccountMigration as AccountMigrationDomainStore, Snackbars, Localisation } from '@stores/domains';
import { loadable } from '@stores/domains/Loader/decorators';
import { TYPES } from '@stores/types';
import { noop } from '@utils/helpers';
import { LoaderName } from './constants';

@injectable()
export class AccountMigrationPage {
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.AccountMigration) accountMigration: AccountMigrationDomainStore;
  @inject(TYPES.Snackbars) snackbars: Snackbars;
  @inject(TYPES.Localisation) localisation: Localisation;

  @loadable(LoaderName.PageInit)
  async init(params: GenericObject, history: History): Promise<void> {
    await this.accountMigration.getAccountMigrationData(params.migrationId as string).catch(noop);

    if (this.accountMigration.migrationData?.data?.attributes?.requireWebSession) {
      sessionStorage.setItem(ACCOUNT_MIGRATION_ID_KEY, `${params.migrationId}`);

      history.push(Routes.GameAccounts.url({ lang: this.localisation.locale as string }));
    }
  }
}
