import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { CSSProperties } from "react";

import { device, gridConfig } from "../../../design-tokens/dimensions";
import { Container } from "../../Container";

/**
 * Calculates the padding of a row at each grid breakpoint based on the given config.
 */
const getRowPaddingStyles = () => {
  const mediaQueries = (Object.keys(gridConfig) as Array<keyof typeof gridConfig>).map((key) => {
    // Set the padding to the specified value minus 1/2 the gutter as the first and last item per row do not have a gutter
    return `@media(${device[key]}) {padding: 0 calc(${gridConfig[key].padding} - (${gridConfig[key].gutter} / 2));}`;
  });

  return css`
    ${mediaQueries.join()}
  `;
};

export const FlexRowRoot = styled(Container)<{
  alignItems: CSSProperties["alignItems"];
  justifyContent: CSSProperties["justifyContent"];
}>`
  align-items: ${({ alignItems }) => alignItems};
  flex-flow: row wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  ${getRowPaddingStyles}
`;
