/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { ParsedUrlQuery } from 'querystring';
import { injectable, inject } from 'inversify';
import { action, computed, observable, makeObservable } from 'mobx';
import { Routes } from '@common/routes';
import { DEFAULT_LOCALE_KEY } from '@i18n/config';
import { Loader, Localisation, Snackbars } from '@stores/domains';
import { Requests } from '@stores/domains/Auth/Requests';
import { notify } from '@stores/domains/Snackbars/decorators';
import { TYPES } from '@stores/types';
import { StatusCode, ErrorCodes } from '@utils/constants';
import { checkErrorStatusCode, isErrorCodeMatch, extractAxiosErrorResponse } from '@utils/network';
import { LoginInfo, AuthStatus, LogoutResponse, RegistrationInfo } from './types';

@injectable()
export class Auth {
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Localisation) localisation: Localisation;
  @inject(TYPES.Snackbars) snackbars: Snackbars;

  authStatus: AuthStatus = AuthStatus.UNKNOWN;

  constructor() {
    makeObservable(this, {
      authStatus: observable,
      isAuthenticated: computed,
      setAuthState: action
    });
  }

  setAuthState(state: AuthStatus): void {
    this.authStatus = state;
  }

  get isAuthenticated(): boolean {
    return this.authStatus === AuthStatus.AUTHORIZED;
  }

  @notify()
  async checkAuthStatus(): Promise<void> {
    try {
      await Requests.checkAuthStatus();
      this.setAuthState(AuthStatus.AUTHORIZED);
    } catch (err) {
      const isUnauthorized = checkErrorStatusCode(StatusCode.Unauthorized, err);

      if (isUnauthorized) {
        this.setAuthState(AuthStatus.UNAUTHORIZED);
      } else {
        return Promise.reject(err);
      }
    }
  }

  @notify()
  async login(locale: string): Promise<LoginInfo> {
    const params = { lang: locale };

    if (this.authStatus === AuthStatus.FORBIDDEN) {
      params['reauth'] = 'true';
    }

    return Requests.login({ params });
  }

  @notify()
  async startRegistration(locale: string, migrationId: string): Promise<RegistrationInfo> {
    return Requests.startRegistration({ lang: locale, migrationId });
  }

  @notify()
  async getAuthTokens(queryParams: ParsedUrlQuery, locale?: string): Promise<void> {
    try {
      await Requests.getAuthTokens(queryParams, { params: { lang: locale } });
    } catch (e) {
      if (isErrorCodeMatch(extractAxiosErrorResponse(e), ErrorCodes.ValidationError)) {
        window.location.assign(Routes.CommonError.url({ lang: locale || DEFAULT_LOCALE_KEY }));
      } else {
        throw e;
      }
    }
  }

  async logout(locale = DEFAULT_LOCALE_KEY): Promise<LogoutResponse> {
    return await Requests.logout({ lang: locale });
  }
}
