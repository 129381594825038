/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { injectable, inject } from 'inversify';
import { makeObservable, observable, action, computed } from 'mobx';
import { ACCOUNT_MIGRATION_ID_KEY, MIGRATED_CHARACTER_NAME } from '@common/constants';
import { Loader, Auth, Snackbars, Localisation, User } from '@stores/domains';
import { withAuth } from '@stores/domains/Auth/decorators';
import { loadable } from '@stores/domains/Loader/decorators';
import { notify } from '@stores/domains/Snackbars/decorators';
import { TYPES } from '@stores/types';
import { ErrorCodes, StatusCode } from '@utils/constants';
import { shortPoll } from '@utils/misc';
import { isErrorCodeMatch, extractAxiosErrorResponse, checkErrorStatusCode } from '@utils/network';
import { ErrorCodeMessages } from '@utils/translations';
import { LoaderName } from './constants';
import { Requests } from './Requests';
import {
  AccountMigrationData,
  AccountMigrationState,
  MigrationCandidateView,
  EligibleMigrationCandidate,
  StartAccountMigrationData
} from './types';
import { checkMigrationAwaitingTokenReadinessOrFailure } from './validators';

@injectable()
export class AccountMigration {
  @inject(TYPES.Auth) auth: Auth;
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Localisation) localisation: Localisation;
  @inject(TYPES.Snackbars) snackbars: Snackbars;
  @inject(TYPES.User) user: User;

  migrationData: AccountMigrationData | null;
  migrationUnavailable = false;

  eligibleCandidates: EligibleMigrationCandidate[] | null = null;

  constructor() {
    makeObservable(this, {
      migrationData: observable,
      migrationUnavailable: observable,
      eligibleCandidates: observable,
      isReceiveMarketingEmailsConsented: computed,
      hasEligibleCandidates: computed,
      setMigrationData: action,
      setMigrationUnavailable: action,
      clearMigrationData: action,
      setEligibleCandidates: action
    });
  }

  setMigrationData(accountMigrationData: AccountMigrationData): void {
    this.migrationData = accountMigrationData;
  }

  setMigrationUnavailable(migrationUnavailable: boolean): void {
    this.migrationUnavailable = migrationUnavailable;
  }

  clearMigrationData(): void {
    this.migrationData = null;
  }

  get isReceiveMarketingEmailsConsented(): boolean {
    return !!this.migrationData?.data.receiveMarketingEmailsConsented;
  }

  setEligibleCandidates(eligibleForMigrationCandidates: EligibleMigrationCandidate[]): void {
    this.eligibleCandidates = eligibleForMigrationCandidates;
  }

  get hasEligibleCandidates(): boolean {
    if (this.eligibleCandidates) {
      return this.eligibleCandidates.length > 0;
    }

    return false;
  }

  @withAuth
  @loadable(LoaderName.GetMigrationData)
  async getAccountMigrationData(migrationId: string): Promise<void> {
    try {
      const accountMigrationResponse = await Requests.getAccountMigrationData(migrationId);

      this.setMigrationData(accountMigrationResponse);
    } catch (e) {
      const isAuthError = checkErrorStatusCode([StatusCode.Unauthorized, StatusCode.Forbidden], e);

      if (!isAuthError) {
        if (isErrorCodeMatch(extractAxiosErrorResponse(e), ErrorCodes.FeatureUnavailable)) {
          sessionStorage.removeItem(ACCOUNT_MIGRATION_ID_KEY);

          this.setMigrationUnavailable(true);
        }
      }

      throw e;
    }
  }

  @loadable(LoaderName.StartRegistration, false)
  @notify()
  async startRegistration(locale: string = this.localisation.locale as string, migrationId: string): Promise<void> {
    const { registrationLink } = await this.auth.startRegistration(locale, migrationId);

    sessionStorage.setItem(ACCOUNT_MIGRATION_ID_KEY, `${migrationId}`);

    window.location.assign(registrationLink);
  }

  @withAuth
  @loadable(LoaderName.FinishMigrationFlow)
  async finishAccountMigrationFlow(migrationId: string, receiveEmails: boolean): Promise<void> {
    try {
      const accountMigrationResponse = await Requests.finishAccountMigrationFlow(migrationId, { receiveEmails });

      this.setMigrationData(accountMigrationResponse);

      if (accountMigrationResponse.state !== AccountMigrationState.Completed) {
        this.clearMigrationData();
        sessionStorage.removeItem(ACCOUNT_MIGRATION_ID_KEY);

        this.snackbars.enqueueDefaultError(ErrorCodeMessages.GeneralError);
      }
    } catch (e) {
      const isAuthError = checkErrorStatusCode([StatusCode.Unauthorized, StatusCode.Forbidden], e);

      if (!isAuthError) {
        this.clearMigrationData();
        sessionStorage.removeItem(ACCOUNT_MIGRATION_ID_KEY);

        if (isErrorCodeMatch(extractAxiosErrorResponse(e), ErrorCodes.TooManyAccounts)) {
          this.snackbars.enqueueDefaultError(ErrorCodeMessages.LimitOfLinkedAccounts);
        } else {
          this.snackbars.enqueueDefaultError(ErrorCodeMessages.GeneralError);
        }
      } else {
        throw e;
      }
    }
  }

  @withAuth
  @loadable(LoaderName.GetEligibleCandidates)
  async getEligibleCandidates(view: MigrationCandidateView): Promise<void> {
    const candidates = await Requests.getEligibleCandidates({ params: { view } });
    this.setEligibleCandidates(candidates);
  }

  @withAuth
  @loadable(LoaderName.StartAccountMigration)
  async startAccountMigration(data: StartAccountMigrationData): Promise<AccountMigrationData> {
    let migrationData: AccountMigrationData;

    migrationData = await Requests.startAccountMigration(data);
    const { state, migrationId } = migrationData;

    if (state === AccountMigrationState.Init) {
      migrationData = await shortPoll(Requests.getAccountMigrationData, checkMigrationAwaitingTokenReadinessOrFailure, [
        migrationId
      ]);
    }

    this.setMigrationData(migrationData);

    return migrationData;
  }

  @withAuth
  async migrateEligibleCandidate(characterId: string): Promise<void> {
    const migrationData = await Requests.doSimpleAccountMigration({ characterId });

    if (migrationData.state === AccountMigrationState.Completed) {
      sessionStorage.setItem(
        MIGRATED_CHARACTER_NAME,
        migrationData.data.displayName || 'gameAccounts:item:noDisplayName'
      );
    } else {
      return Promise.reject();
    }
  }

  @withAuth
  @notify()
  async upgradeAccount(): Promise<void> {
    const { migrationLink } = await Requests.startAccountUpgrade();

    window.location.assign(migrationLink);
  }
}
