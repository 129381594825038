/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { History } from 'history';
import { injectable, inject } from 'inversify';
import { makeObservable, observable, action, computed } from 'mobx';
import { ACCOUNT_MIGRATION_ID_KEY, QueryParamsName } from '@common/constants';
import { CharacterStatus, GenericObject } from '@common/types';
import {
  Loader,
  Localisation,
  Snackbars,
  User,
  AccountMigration as AccountMigrationDomainStore,
  GameAccounts,
  Auth
} from '@stores/domains';
import { AccountMigrationState, MigrationCandidateView } from '@stores/domains/AccountMigration/types';
import { withAuth } from '@stores/domains/Auth/decorators';
import { loadable } from '@stores/domains/Loader/decorators';
import { notify } from '@stores/domains/Snackbars/decorators';
import { TYPES } from '@stores/types';
import { noop, parseBool } from '@utils/helpers';
import { ErrorCodeMessages, SuccessCodeMessages } from '@utils/translations';
import { LoaderName } from './constants';
import { Requests } from './Requests';
import { ActiveLinkedCharacter, ArchivedLinkedCharacter, LinkedCharacter } from './types';

@injectable()
export class GameAccountsPage {
  @inject(TYPES.Auth) auth: Auth;
  @inject(TYPES.Loader) loader: Loader;
  @inject(TYPES.Localisation) localisation: Localisation;
  @inject(TYPES.Snackbars) snackbars: Snackbars;
  @inject(TYPES.User) user: User;
  @inject(TYPES.AccountMigration) accountMigration: AccountMigrationDomainStore;
  @inject(TYPES.GameAccounts) gameAccounts: GameAccounts;

  shouldOpenImportDialog = false;
  isCharacterRankable = false;
  activeCharacters: ActiveLinkedCharacter[] = [];
  initialActiveCharactersRank: ActiveLinkedCharacter[] = [];
  archivedCharacters: ArchivedLinkedCharacter[] = [];
  initialArchivedCharactersRank: ArchivedLinkedCharacter[] = [];
  limitOfLinkedCharacters: number | null = null;

  constructor() {
    makeObservable(this, {
      shouldOpenImportDialog: observable,
      isCharacterRankable: observable,
      activeCharacters: observable,
      archivedCharacters: observable,
      limitOfLinkedCharacters: observable,
      hasCharacters: computed,
      setLimitOfLinkedCharacters: action,
      setShouldOpenImportDialog: action,
      setIsCharacterRankable: action,
      setActiveCharacters: action,
      setInitialActiveCharactersRank: action,
      setArchivedCharacters: action,
      setInitialArchivedCharactersRank: action
    });
  }

  setLimitOfLinkedCharacters(limitOfLinkedCharacters: number): void {
    this.limitOfLinkedCharacters = limitOfLinkedCharacters;
  }

  setShouldOpenImportDialog(state: boolean): void {
    this.shouldOpenImportDialog = state;
  }

  setIsCharacterRankable(state: boolean): void {
    this.isCharacterRankable = state;
  }

  handleQueryAction(history: History): void {
    const queryParams = new URLSearchParams(history.location.search);

    if (queryParams.has(QueryParamsName.OpenImportDialog)) {
      if (parseBool(queryParams.get(QueryParamsName.OpenImportDialog))) {
        this.setShouldOpenImportDialog(true);
      }

      queryParams.delete(QueryParamsName.OpenImportDialog);
      history.replace({
        search: queryParams.toString()
      });
    }
  }

  get hasCharacters(): boolean {
    return !!(this.activeCharacters.length || this.archivedCharacters.length);
  }

  setActiveCharacters(activeCharacters: ActiveLinkedCharacter[]): void {
    this.activeCharacters = activeCharacters;
  }

  setInitialActiveCharactersRank(initialActiveCharactersRank: ActiveLinkedCharacter[]): void {
    this.initialActiveCharactersRank = initialActiveCharactersRank;
  }

  setArchivedCharacters(archivedCharacters: ArchivedLinkedCharacter[]): void {
    this.archivedCharacters = archivedCharacters;
  }

  setInitialArchivedCharactersRank(initialArchivedCharactersRank: ArchivedLinkedCharacter[]): void {
    this.initialArchivedCharactersRank = initialArchivedCharactersRank;
  }

  changeCharacterArchiveStatus(linkedCharacter: LinkedCharacter): void {
    let updatedActiveCharacters: ActiveLinkedCharacter[];
    let updatedArchivedCharacters: ArchivedLinkedCharacter[];

    if (linkedCharacter.characterStatus === CharacterStatus.Archived) {
      updatedArchivedCharacters = this.archivedCharacters.filter(archivedAccount => {
        return linkedCharacter.accountId !== archivedAccount.accountId;
      });
      updatedActiveCharacters = [
        ...this.activeCharacters,
        { ...linkedCharacter, characterStatus: CharacterStatus.Active }
      ];
    } else {
      updatedActiveCharacters = this.activeCharacters.filter(activeAccount => {
        return linkedCharacter.accountId !== activeAccount.accountId;
      });
      updatedArchivedCharacters = [
        ...this.archivedCharacters,
        { ...linkedCharacter, characterStatus: CharacterStatus.Archived }
      ];
      updatedArchivedCharacters.sort(
        (compare, target) => new Date(compare.createdAt).getDate() - new Date(target.createdAt).getDate()
      );
    }

    this.setActiveCharacters(updatedActiveCharacters);
    this.setArchivedCharacters(updatedArchivedCharacters);
  }

  @withAuth
  @loadable(LoaderName.GetAccounts)
  async getCharacters(): Promise<void> {
    const { active, archived } = await Requests.getAccounts();

    this.setActiveCharacters(active);
    this.setArchivedCharacters(archived);
    this.setInitialActiveCharactersRank(active);
    this.setInitialArchivedCharactersRank(archived);
  }

  @withAuth
  @loadable(LoaderName.SaveAccountsRank)
  @notify(SuccessCodeMessages.GeneralSave, ErrorCodeMessages.CommonError)
  async saveCharactersRank(): Promise<void> {
    const rankedCharacterIds = this.activeCharacters.map(linkedCharacter => linkedCharacter.accountId);
    const linkedCharactersRank = {
      accountParentId: this.activeCharacters[0]?.accountParentId || this.archivedCharacters[0]?.accountParentId,
      rankedCharacterIds
    };

    try {
      await Requests.saveAccountsRank(linkedCharactersRank);
    } catch (e) {
      this.setActiveCharacters(this.initialActiveCharactersRank);
      throw e;
    }

    this.setInitialActiveCharactersRank(this.activeCharacters);
    this.setInitialArchivedCharactersRank(this.archivedCharacters);
  }

  @withAuth
  @notify()
  async getLimitOfLinkedCharacters(): Promise<void> {
    const { maxAccounts } = await Requests.getLimitOfLinkedCharacters();
    this.setLimitOfLinkedCharacters(maxAccounts);
  }

  async checkCharacterPresence(): Promise<boolean> {
    const migrationId = sessionStorage.getItem(ACCOUNT_MIGRATION_ID_KEY);

    if (migrationId) {
      this.gameAccounts.setSkipCharactersCheck(true);

      return true;
    }

    if (!this.hasCharacters) {
      await this.getCharacters();
    }

    if (this.hasCharacters) {
      this.gameAccounts.setSkipCharactersCheck(true);

      return true;
    }

    return false;
  }

  @loadable(LoaderName.PageInit)
  async init(params: GenericObject, history: History): Promise<void> {
    if (!this.user.userState?.profileData) {
      await this.user.current();
    }

    const migrationId = sessionStorage.getItem(ACCOUNT_MIGRATION_ID_KEY);

    if (migrationId) {
      try {
        await this.accountMigration.getAccountMigrationData(migrationId);

        if (this.accountMigration.migrationData?.state !== AccountMigrationState.AwaitingToken) {
          this.snackbars.enqueueDefaultError(ErrorCodeMessages.GeneralError);
        } else if (!this.accountMigration.migrationData?.data?.attributes?.requireWebSession) {
          await this.accountMigration.finishAccountMigrationFlow(
            migrationId,
            this.accountMigration.isReceiveMarketingEmailsConsented
          );
        }
      } catch (e) {
        this.snackbars.enqueueDefaultError(ErrorCodeMessages.GeneralError);
      }
    }

    this.handleQueryAction(history);
    await this.accountMigration.getEligibleCandidates(MigrationCandidateView.Hidden).catch(noop);
    await this.getLimitOfLinkedCharacters().catch(noop);
  }
}
