import type { HTMLAttributes } from "react";

import { Root, Text } from "./styles";

type TagProps = {
  /**
   * The size of the tag
   */
  entitySize?: "m" | "l";
  /**
   * Content of the Tag
   */
  children: string;
} & HTMLAttributes<HTMLSpanElement>;

/**
 * Small rounded rectangle element for labelling or tagging content.
 */
export function Tag({ entitySize = "m", children, ...rest }: TagProps): JSX.Element {
  return (
    <Root data-testid={Tag.name} entitySize={entitySize} {...rest}>
      <Text>{children}</Text>
    </Root>
  );
}
