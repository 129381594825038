/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

type PageHelmetProps = {
  title: string;
};

export const PageHelmet: FunctionComponent<PropsWithChildren<PageHelmetProps>> = ({ title, children }) => {
  const { t } = useTranslation();

  return (
    <Helmet>
      {/* Title and standard meta information */}
      <title>{t(title)}</title>
      {children}
    </Helmet>
  );
};
