import type { ComponentPropsWithoutRef } from "react";
import type { CSSProperties } from "react";

import type { DeviceConfig } from "../../design-tokens/dimensions";
import { getSrcSet, ImageElement } from "./styles";

export type ObjectPositionConfig = DeviceConfig<CSSProperties["objectPosition"]>;

export type SrcSetConfig = Omit<DeviceConfig<string>, "base">;

export type ImageProps = {
  /**
   * The srcset to use, organised by breakpoint key.
   */
  srcSetConfig?: SrcSetConfig;
  /**
   * Object-position to use, organised by breakpoint key.
   */
  objectPosition?: ObjectPositionConfig;
  /**
   * Alt text for the images.
   */
  alt: string;
} & ComponentPropsWithoutRef<"img">;

/**
 * Renders an image onto the view. This image can be changed at different screen breakpoints.
 */
export function Image({ srcSetConfig, objectPosition, alt, ...rest }: ImageProps): JSX.Element {
  return (
    <ImageElement
      objectPosition={objectPosition}
      srcSet={srcSetConfig ? getSrcSet(srcSetConfig) : undefined}
      alt={alt}
      data-testid={Image.name}
      {...rest}
    />
  );
}
