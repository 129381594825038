/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { injectable } from 'inversify';
import { makeObservable, observable, action } from 'mobx';
import { LoaderState, LoaderItem } from './types';

@injectable()
export class Loader {
  loaders: { [key: string]: LoaderItem } = {};

  constructor() {
    makeObservable(this, {
      loaders: observable,
      setLoaderState: action
    });
  }

  setLoaderState = (loaderName: string, state: LoaderState): void => {
    this.loaders[loaderName] = { state };
  };

  isSetOnce = (loaderName: string): boolean => {
    const loader = this.loaders[loaderName];
    return !!loader;
  };

  isLoading = (loaderName: string): boolean => {
    const loaderState = this.loaders[loaderName]?.state;
    return loaderState === LoaderState.Pending;
  };
}
