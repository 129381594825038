/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { Snackbar } from '@components/notifications';
import { useStore } from '@stores/useStore';

export const SnackbarContainer: FunctionComponent = observer(() => {
  const {
    snackbars: { active, hideCurrentSnackbar, showNextSnackbar }
  } = useStore();
  const { open, id, item } = active;

  if (open && item) {
    return (
      <Snackbar
        key={id}
        duration={item.duration}
        level={item.level}
        onClose={hideCurrentSnackbar}
        afterClose={showNextSnackbar}
      >
        {item.message}
      </Snackbar>
    );
  }

  return null;
});
