import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { rem } from "polished";

import { lineClamp } from "../../../utils/line-clamp";
import { colors } from "../../design-tokens/colors";
import { device, globalTextMaxWidth, sizes } from "../../design-tokens/dimensions";
import { typography } from "../../design-tokens/typography";
import type { ParagraphProps } from "./Paragraph";

const defaultColor = colors.white;

export const getParagraphStyle = (props: ParagraphProps) => {
  return css`
    color: ${props.color ?? defaultColor};
    font-family: ${typography.families.body};
    font-size: ${typography.sizes[16]};
    ${lineClamp(typography.lineHeights[24], props.lines)};
    margin: ${sizes.s12.rem} 0;
    max-width: ${rem(globalTextMaxWidth)};
    text-align: ${props.align};

    @media (${device.large}) {
      font-size: ${props.entitySize === "l" ? typography.sizes[18] : typography.sizes[16]};
    }
  `;
};

export const Root = styled.p<ParagraphProps>`
  ${getParagraphStyle}
`;
