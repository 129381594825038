import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

import type { ColumnConfig } from "../../../design-tokens/dimensions";
import { device, gridConfig, gridTotalColumns } from "../../../design-tokens/dimensions";

/**
 * Calculates the widths and padding of an item at each grid breakpoint based on the given config.
 *
 * @param columnConfig - {@link ColumnConfig} which defines sizes at different grid breakpoints for this item.
 */
export const getItemWidthStyles = (columnConfig?: ColumnConfig): SerializedStyles => {
  // If nothing is provided the width defaults to auto (flexes the item to use the remaining space)
  let currentColumnCount: number | "auto" = "auto";
  // Loop through each Grid Breakpoint defined in the styles gridConfig
  const mediaQueries = (Object.keys(gridConfig) as Array<keyof typeof gridConfig>).map((key) => {
    // Set the current number of columns to the required setting at this point or to the same as the previous loop value
    currentColumnCount = columnConfig ? columnConfig[key] ?? currentColumnCount : "auto";
    // Generate the required media query and properties for this grid breakpoint and append to the itemStyles
    return `@media(${device[key]}) {padding: 0 calc(${gridConfig[key].gutter} / 2); width: ${
      // For numbers set the width to the percentage of space
      currentColumnCount && typeof currentColumnCount === "number"
        ? `calc((${currentColumnCount} / ${gridTotalColumns}) * 100%); flex: 0 0 auto;`
        : "auto; flex: 1 1 0;"
    }}`;
  });

  return css`
    ${mediaQueries.join()}
  `;
};

/**
 * This root element builds and applies the correct container width.
 */
export const FlexItemRoot = styled.div<{ columnConfig?: ColumnConfig }>`
  ${({ columnConfig }) => getItemWidthStyles(columnConfig)}
`;
