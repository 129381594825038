/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { LOGIN_PORTAL_WEBSITE_BASE_URL } from '@common/envs';

export const TARGET_ROUTE_STORAGE_KEY = 'runescape-accounts__target-route';
export const NEWCOMER_TARGET_ROUTE_STORAGE_KEY = 'runescape-accounts__newcomer-target-route';

export const ACCOUNT_MIGRATION_ID_KEY = 'migrationId';
export const MIGRATED_CHARACTER_NAME = 'migratedCharacterName';

export const TERMS_AND_CONDITIONS_URL = 'https://www.jagex.com/<lang>/terms';

export const MIGRATION_FIND_OUT_MORE_LINK =
  'https://help.jagex.com/hc/<lang>/articles/13413514881937-Downloading-the-Jagex-Launcher-on-Linux';

export const GENERIC_RS_AVATAR_URL = 'https://secure.runescape.com/m=avatar-rs/default_chat.png';

export const DEFAULT_SHORT_POLLING_RETRY_TIME = 2000; // ms

// eslint-disable-next-line max-len
export const LOGIN_PORTAL_INTERCEPTED_REGISTRATION_CONTINUE = `${LOGIN_PORTAL_WEBSITE_BASE_URL}/api/registrations/intercepted/continue`;

export enum QueryParamsName {
  OpenImportDialog = 'openImportDialog'
}

export enum CookieName {
  SkipCookieDeclaration = 'skipCookieDeclaration'
}
