/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import css from '@emotion/css';
import styled from '@emotion/styled';
// TODO: These icons need to be taken from JDS and removed. Not done before, since the update is going to happen later.
import Check from '@static/svg/check.svg';
import Cross from '@static/svg/cross.svg';

const defaultIconSize = css`
  width: 3rem;
  height: 3rem;
`;

export const CheckIcon = styled(Check)`
  fill: ${props => props.color};
  ${defaultIconSize}
`;
export const CrossIcon = styled(Cross)`
  fill: ${props => props.color};
  ${defaultIconSize}
`;
