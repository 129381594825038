import type { ComponentProps } from "react";

import type { Button as BoxButton } from "../../../atoms/Button";
import { BoxFlowButton } from "../styles";

/**
 * Renders a full width {@link Button} for use in the {@link BoxFlow} interface
 */
export function Button({ children, ...rest }: ComponentProps<typeof BoxButton>): JSX.Element {
  return (
    <BoxFlowButton data-testid={`BoxFlow${Button.name}`} {...rest}>
      {children}
    </BoxFlowButton>
  );
}
