/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export const ErrorCodeMessages = {
  CommonError: 'general:errors:commonNotification',
  GeneralError: 'general:errors:commonError',
  AddGameAccount: 'gameAccounts:addAccount:errorMessage',
  LimitOfLinkedAccounts: 'gameAccounts:addAccount:LimitOfLinkedAccounts',
  EmailVerificationCode: 'emailVerification:errors:codeErrorText'
};

export const SuccessCodeMessages = {
  AddGameAccount: 'gameAccounts:addAccount:successMessage',
  GeneralSave: 'general:success:save'
};

export enum PageDocumentTitle {
  SelectGameAccount = 'selectGameAccount:meta:pageTitle',
  GeneralErrorPage = 'Error | Jagex',
  Newcomers = 'newcomersPage:meta:pageTitle',
  BeginYourAdventure = 'beginYourAdventure:meta:pageTitle'
}
