/*
 * Copyright 1999-2024 Jagex Ltd.
 */
export const TYPES = {
  // Domains
  Loader: Symbol('Loader'),
  Localisation: Symbol('Localisation'),
  Snackbars: Symbol('Snackbars'),
  Auth: Symbol('Auth'),
  User: Symbol('User'),
  AccountSelect: Symbol('AccountSelect'),
  AccountMigration: Symbol('AccountMigration'),
  GameAccounts: Symbol('GameAccounts'),

  // Views
  GameAccountsPage: Symbol('GameAccountsPage'),
  AccountMigrationPage: Symbol('AccountMigrationPage'),
  NewcomersPage: Symbol('NewcomersPage'),
  BeginYourAdventurePage: Symbol('BeginYourAdventurePage')
};
