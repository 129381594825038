/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { LOCALE_REGEX, LANGUAGE_REGEX, Language } from './config';
import { LANG_QUERY_NAME } from './constants';
import { Locale, TransformedDict } from './types';

export const setLocaleIntoSessionStorage = (language: string): void => {
  sessionStorage.setItem('language', language);
};

export const extractLocaleFromSessionStorage = (): string | null => {
  const language = sessionStorage.getItem('language');
  sessionStorage.removeItem('language');

  return language;
};

export const matchesFormat = (str: string, regex: RegExp): string | null => {
  const matches = str.match(regex);

  return matches ? matches[0] : null;
};

export const matchesLocaleFormat = (localeStr: string): string | null => {
  return matchesFormat(localeStr, LOCALE_REGEX);
};

export const matchesLanguageFormat = (localeStr: string): string | null => {
  return matchesFormat(localeStr, LANGUAGE_REGEX);
};

export const extractLocaleFromURL = (): string | null => {
  if (window) {
    const { pathname } = window.location;
    const language = matchesLocaleFormat(pathname) || matchesLanguageFormat(pathname);

    if (language) {
      return language.replace('/', '');
    }
  }

  return null;
};

export const extractLocaleFromQueryParams = (): string | null => {
  if (window) {
    const { search } = window.location;

    return new URLSearchParams(search).get(LANG_QUERY_NAME);
  }

  return null;
};

export const transformDict = (translation: Locale): TransformedDict => {
  const [lang, dict] = Object.entries(translation)[0];

  return { lang, dict };
};

export function fromISO639(langCode: string): Language {
  switch (langCode) {
    case 'fr':
    case 'fr-FR':
      return Language.FRENCH;
    case 'de':
    case 'de-DE':
      return Language.GERMAN;
    case 'pt':
    case 'pt-BR':
      return Language.PORTUGUESE;
    case 'en':
    case 'en-GB':
    case 'en-US':
    default:
      return Language.ENGLISH;
  }
}
