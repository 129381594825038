/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { SnackbarContainer } from '@components/notifications';
import { GlobalStyles } from '@styles/global';
import { MainContent } from './styled';

export const UIWrapper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <MainContent>
        <SnackbarContainer />
        {children}
      </MainContent>
    </>
  );
};
