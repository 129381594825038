import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { ComponentPropsWithoutRef } from "react";

import { BaseInput } from "../../atoms/BaseInput";
import { colors } from "../../design-tokens/colors";
import { sizes } from "../../design-tokens/dimensions";
import { typography } from "../../design-tokens/typography";

const displaceLabelTransform = css`
  transform-origin: left center;
  transform: translateY(-${typography.sizes["14"]});
  font-size: ${typography.sizes["14"]};
`;

export const Root = styled.div``;

const labelMaxWidth = sizes.s512.rem;
const labelMarginLeft = sizes.s24.rem;

export const LabelContainer = styled.label<{ displaceLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${labelMaxWidth};
  position: relative;

  &:focus-within span {
    ${displaceLabelTransform};
  }

  span {
    ${({ displaceLabel }) => displaceLabel && displaceLabelTransform};
  }
`;

export const LabelText = styled.span<ComponentPropsWithoutRef<typeof BaseInput>>`
  color: ${({ isInvalid }) => (isInvalid ? colors.red : colors.baseLightest)};
  font-size: ${typography.sizes["18"]};
  line-height: ${sizes.s56.rem};
  margin-left: ${labelMarginLeft};
  pointer-events: none;
  position: absolute;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  max-width: calc(${labelMaxWidth} - ${labelMarginLeft});
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InputControl = styled(BaseInput)<{ hasSuffix?: boolean }>`
  padding-bottom: 0;
  padding-top: ${sizes.s16.rem};
  padding-right: ${({ hasSuffix }) => (hasSuffix ? sizes.s56.rem : sizes.s24.rem)};
`;
