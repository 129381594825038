/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { FinaliseSelectionResponse, SelectAccountsBody, SelectAccountsResponseData } from './types';
export const Requests = {
  getAccountSelection: (options: AxiosRequestConfig = {}): Promise<SelectAccountsResponseData> => {
    return extractResponse<SelectAccountsResponseData>(api.get(`/accountSelection`, options));
  },
  selectAccount: (body: SelectAccountsBody): Promise<FinaliseSelectionResponse> => {
    return extractResponse<FinaliseSelectionResponse>(api.patch(`/accountSelection`, body));
  },
  createAccount: (options: AxiosRequestConfig = {}): Promise<FinaliseSelectionResponse> => {
    return extractResponse<FinaliseSelectionResponse>(api.post(`/accountSelection`, options));
  }
};
