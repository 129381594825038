/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { AxiosRequestConfig } from 'axios';
import { api, extractResponse } from '@utils/network';
import { UserVerificationMethod } from './constants';
import {
  UserCurrentResponse,
  UserMarketingPreferencesResponse,
  UserEmailStatus,
  EmailVerificationSubmitData,
  CodeVerificationState,
  UserTypeInformation,
  NewcomerAction
} from './types';

export const Requests = {
  current: (options: AxiosRequestConfig = {}): Promise<UserCurrentResponse> => {
    return extractResponse<UserCurrentResponse>(api.get('/users/current', options));
  },
  currentType: (options: AxiosRequestConfig = {}): Promise<UserTypeInformation> => {
    return extractResponse<UserTypeInformation>(api.get('/users/current/type', options));
  },
  currentMarketingPreferences: (options: AxiosRequestConfig = {}): Promise<UserMarketingPreferencesResponse> => {
    return extractResponse<UserMarketingPreferencesResponse>(api.get('/users/current/marketingPreferences', options));
  },
  getEmailStatus: (options: AxiosRequestConfig = {}): Promise<UserEmailStatus> => {
    return extractResponse<UserEmailStatus>(api.get('/users/current/emailStatus', options));
  },
  startVerification: (
    method: UserVerificationMethod,
    options: AxiosRequestConfig = {}
  ): Promise<CodeVerificationState> => {
    return extractResponse<CodeVerificationState>(api.post(`/users/current/verifications/${method}`, null, options));
  },
  proceedVerification: (
    id: string,
    method: UserVerificationMethod,
    data: EmailVerificationSubmitData,
    options: AxiosRequestConfig = {}
  ): Promise<CodeVerificationState> => {
    return extractResponse<CodeVerificationState>(
      api.post(`/users/current/verifications/${id}/inputs/${method}`, data, options)
    );
  },
  getNewcomerAction: (options: AxiosRequestConfig = {}) => {
    return extractResponse<NewcomerAction>(api.get('/users/current/newcomers/action', options));
  }
};
