import type { HTMLAttributes } from "react";
import type { CSSProperties } from "react";

import type { ColorValue } from "../../design-tokens/colors";
import { Root } from "./styles";

export type SmallTextProps = {
  /**
   * Number of lines the text is restricted to. See {@link @jagex/jds#lineClamp}.
   */
  lines?: number;
  /**
   * Alignment of text within the small.
   */
  align?: CSSProperties["textAlign"];
  /**
   * The font-color of the text. See {@link @jagex/jds#colors}.
   */
  color?: ColorValue;
} & HTMLAttributes<HTMLSpanElement>;

/**
 * A passage of text that is smaller than standard copy - usually terms or legal text.
 */
export function SmallText({
  lines,
  align = "left",
  color,
  children,
  ...rest
}: SmallTextProps): JSX.Element {
  return (
    <Root data-testid={SmallText.name} lines={lines} align={align} color={color} {...rest}>
      {children}
    </Root>
  );
}
