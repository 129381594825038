import type { HTMLAttributes } from "react";
import type { CSSProperties } from "react";

import { globalContentMaxWidth, globalTextMaxWidth } from "../../design-tokens/dimensions";
import { Root } from "./styles";

type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * The css "display" value to use (see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/display}).
   */
  display?: CSSProperties["display"];
  /**
   * The max width the container can grow to, from a predefined list.
   */
  maxWidth?: typeof globalContentMaxWidth | typeof globalTextMaxWidth;
};

/**
 * Renders a block primitive which grows to the max content or text width and is horizontally centered.
 */
export function Container({
  children,
  display = "block",
  maxWidth = globalContentMaxWidth,
  ...rest
}: ContainerProps): JSX.Element {
  return (
    <Root display={display} maxWidth={maxWidth} data-testid={Container.name} {...rest}>
      {children}
    </Root>
  );
}
