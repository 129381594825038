import type { HTMLAttributes } from "react";

import { Root } from "./styles";

type SpacerProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Quantity of spacing units to insert.
   */
  multiplier?: number;
};

/**
 * Renders an empty block with zero height and a bottom margin derived from the provided multiplier.
 */
export function Spacer({ multiplier = 1 }: SpacerProps): JSX.Element {
  return <Root multiplier={multiplier} aria-hidden="true" data-testid={Spacer.name} />;
}
