import type { ComponentProps, CSSProperties, HTMLAttributes } from "react";

import type { Container } from "../../Container";
import { FlexItem } from "../FlexItem";
import { FlexRowRoot } from "./styles";

type FlexRowProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Max width of the row.
   */
  maxWidth?: ComponentProps<typeof Container>["maxWidth"];
  /**
   * Vertical alignment of the children.
   */
  alignItems?: CSSProperties["alignItems"];
  /**
   * Horizontal alignment of the children.
   */
  justifyContent?: CSSProperties["justifyContent"];
};

/**
 * Renders a row to contain flex children.
 */
export function FlexRow({
  maxWidth,
  alignItems = "center",
  justifyContent = "center",
  children,
  ...rest
}: FlexRowProps): JSX.Element {
  return (
    <FlexRowRoot
      display="flex"
      alignItems={alignItems}
      justifyContent={justifyContent}
      maxWidth={maxWidth}
      data-testid={FlexRow.name}
      {...rest}
    >
      {children}
    </FlexRowRoot>
  );
}

/**
 * Composite component setup
 */
FlexRow.Item = FlexItem;
