/*
 * Copyright 1999-2024 Jagex Ltd.
 */
import { Box, JagexLogo, VistaContainer } from '@jagex/design_jagex';
import React, { FunctionComponent, PropsWithChildren } from 'react';

export const CenteredContainer: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <VistaContainer>
    <Box mb={3}>
      <JagexLogo />
    </Box>
    {children}
  </VistaContainer>
);
